<template>
  <v-app>
    <v-content>
      <div class="page-container">
        <div class="page-content">
          <v-progress-circular
            indeterminate
            size="100"
            color="#fff"
          ></v-progress-circular>
          <div class="page-content-container">
            <h2 class="page-content-title">{{ $t("please_wait") }}</h2>
            <p class="page-content-subtitle">
              {{ $t("redirect_other_platform") }}
            </p>
          </div>
        </div>
      </div>
    </v-content>
  </v-app>
</template>

<script>
import {
  redirectVuePlatform,
  redirectManagerPlatform,
  redirectPartnerPlatform,
} from "@/shared/helpers/redirectToReactApp";
import { UserTypeEnum } from "@/shared/enums/UserType";

export default {
  name: "AppRedirectOtherPlatform",
  data() {
    return {
      userType: null,
      UserTypeEnum,
    };
  },
  async created() {
    this.userType = await JSON.parse(localStorage.getItem("user")).type;
    this.handleRedirectToReact();
  },
  methods: {
    handleRedirectToReact() {
      // TIMEOUT PARA MOSTRAR O LOADING E A MENSAGEM QUE O USUÁRIO VAI SER REDIRECIONADO PARA OUTRA PLATAFORMA.
      setTimeout(() => {
        // manager app
        if (this.userType === this.UserTypeEnum.ManagerUser) {
          redirectManagerPlatform("/dashboard", null);
          return;
        }

        // partner app
        if (this.userType === this.UserTypeEnum.Partner) {
          redirectPartnerPlatform("/dashboard", null);
          return;
        }

        // banker app
        redirectVuePlatform("/dashboard", null);
        localStorage.clear();
      }, 1000);
    },
  },
};
</script>

<style scoped>
.page-container {
  min-height: 100vh;
  background: #111928;
}

.page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.page-content-title {
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-weight: bold;
  color: var(--white);
  margin-bottom: 8px;
  text-align: center;
  margin-top: 24px;
}

.page-content-subtitle {
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: normal;
  color: #9ca3af;
  text-align: center;
}

.page-content-container {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
